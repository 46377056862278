import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Share from '../images/share.svg'

const Wrapper = styled.div`
  position:fixed;
  bottom:50px;
  left:200px;
  right:0;
  background: #444;
  padding:1em;
   z-index:1000;
`

const Link = styled.span`
    text-decoration: underline;
    cursor:pointer;
    opacity: 0.5;
    margin:.5em;
    color:white;
    
    transition: opacity 100ms linear;
    &:hover{
        opacity: .8;
    }
    
    ${p => p.active && `
        opacity:1;
        &:hover{
            opacity: 1;
        }
    `}
`
const Right = styled.div`
    float:right
`

const Close = styled.span`
    opacity:.5;
    cursor:pointer;
    margin:.5em 1em .5em;
    transition: opacity 100ms linear;
    &:hover{
        opacity: 1;
    }
`


const Collection = (props) => {
    let formatPrice = (price) => {
        return props.currency.format.replace('%s', Math.round((price / props.currency.conversion) * 10) / 10)
    }

    let share = () => {
        let ids = props.items.map(item => item.id);
        let data = btoa(JSON.stringify({c: ids}));
        let url = window.location.origin + '/?data=' + encodeURIComponent(data);
        let copy = window.prompt(`Your URL. Ctrl+C to copy.`, url);
        // if (copy) {
        //     const el = document.createElement('textarea');
        //     el.value = url;
        //     document.body.appendChild(el);
        //     el.select();
        //     document.execCommand('copy');
        //     document.body.removeChild(el);
        // }
    }

    return (
        <Wrapper>
            Collection: {props.items.length} items. Total
            price: {formatPrice(props.items.reduce((a, c) => a + c.price.price, 0))} {props.currency.name}

            <Right>
                View:
                <Link active={props.isCollectionView === false} onClick={() => props.changeView(false)}>All items</Link>/
                <Link active={props.isCollectionView} onClick={() => props.changeView(true)}>Collection Only</Link>
                <Link onClick={share}><img src={Share} width={14} alt={'Share'}/></Link>

                <Close onClick={props.onClose}>X</Close>
            </Right>

        </Wrapper>
    )
}

Collection.propTypes = {
    isCollectionView: PropTypes.bool,
    onClose: PropTypes.func,
    changeView: PropTypes.func
};

export default Collection;