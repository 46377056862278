import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ColorSelector from "./ColorSelector";

const Wrapper = styled.div`
    //position:fixed;
    //top:0;
    //right:0;
    //left:200px;
    padding:1em;
    background:#313438;
    z-index: 50;
`

const TextField = styled.input`
    background: transparent;
    border: none;
    border-bottom: 1px solid #0000006b;
    color:white;
    float:left;
    width:${p => p.width !== null ? p.width : 100}px;
    margin-left:1em;
    :focus{
        background: rgba(100,100,100,0.2);
        outline:none;
    }
`

const Right = styled.div`
    float:right;
`
const Left = styled.div`
    float:left;
`

const Toggler = styled.div`
  width:20px;
  line-height: 20px;
  font-size:.6em;
  float:left;
  opacity: .6;
  margin-left:10px;
  cursor: pointer;
`

const TextToggle = styled.div`
  width:20px;
  line-height: 20px;
  font-size:.8em;
  float:left;
  opacity: .4;
  margin-left:10px;
  cursor: pointer;
  transition: all 100ms linear;
  :hover{
    opacity: .6;
  }
  ${p => p.active && `
    opacity: 1;
  `}
`

const Header = (props) => {
    let [name, setName] = useState('')
    let [minPrice, setMinPrice] = useState('')

    let [maxPrice, setMaxPrice] = useState('')

    useEffect(() => {
        props.onNameChange(name);
    }, [name])

    useEffect(() => {
        let min = parseInt(minPrice);
        let max = parseInt(maxPrice);
        min = isNaN(min) ? -1 : min;
        max = isNaN(max) ? -1 : max;
        props.onLimitsChange([min, max]);
    }, [minPrice, maxPrice])

    return (
        <Wrapper>
            <Left>
                <TextField placeholder={'Search'} value={name} onChange={(a) => setName(a.target.value)}/>
                {name.length > 0 && <Toggler onClick={() => setName('')}>X</Toggler>}

                <TextField width={60} placeholder={'Min Price'} value={minPrice}
                           onChange={(a) => setMinPrice(a.target.value)}/>

                <TextField width={60} placeholder={'Max Price'} value={maxPrice}
                           onChange={(a) => setMaxPrice(a.target.value)}/>
                <TextToggle active={props.sale} onClick={props.onToggleSale}>Sale</TextToggle>
            </Left>
            <Right>
                <ColorSelector selected={props.selectedColors} onToggleColor={props.onToggleColor}/>
            </Right>
        </Wrapper>
    )
}

Header.propTypes = {
    onLimitsChange: PropTypes.func,
    onNameChange: PropTypes.func,
    onToggleColor: PropTypes.func,
    colors: PropTypes.array,
    selectedColors: PropTypes.array,
    sale: PropTypes.bool,
    onToggleSale: PropTypes.func
};

export default Header;