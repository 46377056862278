import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
    width:${7 * 19 + 20 + 10}px;
`
const Toggler = styled.div`
  float:right;
  width:20px;
  line-height: 20px;
  font-size:.6em;
  opacity: .6;
  margin-left:10px;
  cursor: pointer;
`

const Colors = styled.div`
    width:${7 * 19}px;
    float:left;
`

const Color = styled.div`
    width:7px;
    float: left;
    height:7px;
    background:#${p => p.hex};
    opacity: 0.2;
    cursor: pointer;
    :hover{
        opacity:.8;
        transform: scale(2);
    }
    ${p => p.selected && `
        opacity:1;
    `}
`

const ColorSelector = (props) => {
    let colors = [
        "EF9A9A", "F48FB1", "CE93D8", "B39DDB", "9FA8DA", "90CAF9", "81D4FA", "80DEEA", "80CBC4", "A5D6A7", "C5E1A5", "E6EE9C", "FFF59D", "FFE082", "FFCC80", "FFAB91", "BCAAA4", "EEEEEE", "B0BEC5",
        "E53935", "D81B60", "8E24AA", "5E35B1", "3949AB", "1E88E5", "039BE5", "00ACC1", "00897B", "43A047", "7CB342", "C0CA33", "FDD835", "FFB300", "FB8C00", "F4511E", "6D4C41", "757575", "546E7A",
        "B71C1C", "880E4F", "4A148C", "311B92", "1A237E", "0D47A1", "01579B", "006064", "004D40", "1B5E20", "33691E", "827717", "F57F17", "FF6F00", "E65100", "BF360C", "3E2723", "212121", "263238"
    ].map(c => c.toLowerCase())

    let activateAll = () => {
        props.onToggleColor(colors)
    }

    let deactivateAll = () => {
        props.onToggleColor([])
    }

    return (
        <Wrapper>
            <Toggler onClick={() => props.selected.length === 0 ? activateAll() : deactivateAll()}>
                {props.selected.length === 0 ? 'O' : 'X'}
            </Toggler>
            <Colors>
                {colors.map(color =>
                    <Color key={color} hex={color} selected={props.selected.indexOf(color) >= 0}
                           onClick={() => props.onToggleColor(color)}/>
                )}
            </Colors>
        </Wrapper>
    )
}

ColorSelector.propTypes = {
    onToggleColor: PropTypes.func,
    colors: PropTypes.array,
    selected: PropTypes.array
};

export default ColorSelector;