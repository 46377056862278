import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

const Wrapper = styled.div`
    //position:fixed;
    //bottom:0;
    //right:0;
    //left:200px;
    background: hsla(227, 0%, 12%, 1);
    padding:.8em;
    z-index: 1000;
`

const Pages = styled.div`
    text-align:center;
    display:grid;
    grid-template-columns:50px 80px ${p => p.pages > 0 ? `repeat( ${p.pages},30px)` : ''} 80px 50px;
     width:${p => 50 * 2 + 80 * 2 + 30 * p.pages}px;
    margin:auto auto;
`

const Page = styled.span`
    padding:3px 10px;
    display:inline-block;
    cursor: pointer;
    opacity:.6;
    ${p => p.disabled && `
        opacity:.2;
    `}
    ${p => p.active && `
        text-decoration:underline;
        font-weight:600;
        opacity:1;
    `}
`

class Paginator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {pager: {}};
    }

    componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            this.setPage(this.props.initialPage);
        }
    }

    setPage(page) {
        let {items, pageSize} = this.props;
        let pager = this.state.pager;

        if (page < 1 || (pager.totalPages > 0 && page > pager.totalPages)) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(items.length, page, pageSize);

        // get new page of items from items array
        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({pager: pager});

        // call change page function in parent component
        this.props.onChangePage(pageOfItems);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        let visiblePages = 10;
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage, endPage;

        if (totalPages <= visiblePages) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= visiblePages / 2 + 1) {
                startPage = 1;
                endPage = visiblePages;
            } else if (currentPage + (visiblePages / 2 - 1) >= totalPages) {
                startPage = totalPages - (visiblePages - 1);
                endPage = totalPages;
            } else {
                startPage = currentPage - visiblePages / 2;
                endPage = currentPage + (visiblePages / 2 - 1);
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);


        currentPage = totalPages > 0 ? currentPage : 0;
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        let pager = this.state.pager;

        if (!pager.pages) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <Wrapper>
                <Pages pages={Math.min(pager.totalPages, 10)}>

                    <Page disabled={pager.currentPage <= 1} onClick={() => {
                        this.setPage(1);
                        window.scrollTo(0, 0);
                    }}>
                        First
                    </Page>
                    <Page disabled={pager.currentPage <= 1} onClick={() => {
                        this.setPage(pager.currentPage - 1);
                        window.scrollTo(0, 0);
                    }}>
                        Previous
                    </Page>
                    {pager.pages.map((page, index) =>
                        <Page key={index} active={pager.currentPage === page}
                              onClick={() => {
                                  this.setPage(page);
                                  window.scrollTo(0, 0);
                              }}>
                            {page}
                        </Page>
                    )}
                    <Page disabled={pager.currentPage === pager.totalPages}
                          onClick={() => {
                              this.setPage(pager.currentPage + 1);
                              window.scrollTo(0, 0);
                          }}>
                        Next
                    </Page>
                    <Page disabled={pager.currentPage === pager.totalPages}
                          onClick={() => {
                              this.setPage(pager.totalPages);
                              window.scrollTo(0, 0);
                          }}>
                        Last
                    </Page>
                </Pages>
            </Wrapper>
        );
    }
}

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
    pageSize: 50
}

Paginator.propTypes = propTypes;
Paginator.defaultProps = defaultProps;

export default Paginator;
