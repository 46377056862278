import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Item from "./Item";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(180px,1fr));
    grid-template-rows: min-content;
    gap: 2em;
    overflow-y:scroll;
    padding:1em;
    z-index: 1000;
`

const ItemsGrid = (props) => {
    return (
        <Wrapper>
            {props.items.map(item =>
                <div key={item.poe_id}>
                    <Item inCollection={props.collection.indexOf(item.id) >= 0}
                          onToggleCollection={props.onToggleCollection}
                          currency={props.currency} onToggleColor={props.onToggleColor} {...item}/>
                </div>
            )}
        </Wrapper>
    )
}

ItemsGrid.propTypes = {
    collection: PropTypes.array,
    onToggleCollection: PropTypes.func,
    currency: PropTypes.object,
    onToggleColor: PropTypes.func
};

export default ItemsGrid;