import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid rgba(150, 150, 150, 0.2);
  //max-width: 250px;
`

const Details = styled.div`
    background: #424957;
    padding: .4em;
    height: 60px;
    font-size: 1em !important;
    letter-spacing: .5px;
    display: grid;
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 1fr auto auto;
    a{
        color:white;
        text-decoration:none;
    }
`

const Price = styled.div`
  grid-column: 2;
  ${p => p.sale && `
      grid-column: 3;
    text-decoration: line-through;
    margin-left:.5em;
  `}
`

const Title = styled.div`
    grid-column: 1 / 4;
`

const Colors = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: .5em;
`

const Color = styled.div`
  background: #${p => p.hex};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.8);
  float: left;
  margin: .1em;
  position: absolute;
  left: 14px;
  bottom: 10px;
  z-index: 5;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  opacity: .0;

  &:nth-child(1) {
    z-index: 6;
  }
`

const CollectionToggle = styled.div`
    position: absolute;
    font-size:1.5em;
    right:10px;
    top:0;
    cursor: pointer;
    opacity: 0;
    transition: all 100ms ease-in-out;
    ${p => p.active && `
        transform:rotate(45deg);
    `}
`

const Icon = styled.div`
    text-align: center;
    background: hsl(0, 0%, 10%);
    position: relative;
    padding: 1em;

    img {
        height: 100px;
        transition: all 100ms ease-in-out;
    }

    &:hover {
        img {
            transform: scale(1.2);
        }
        & ${CollectionToggle} {
            opacity:0.8;
        }

        & ${Color} {
                opacity: 1;

            &:nth-child(2) {
                    left: calc(2 * 14px);
                }

            &:nth-child(3) {
                    left: calc(3 * 14px);
                }

            &:nth-child(4) {
                    left: calc(4 * 14px);
                }

            &:nth-child(5) {
                    left: calc(5 * 14px);
                }

            }
        }
    }
`

const Item = (props) => {
    let formatPrice = (price) => {
        return props.currency.format.replace('%s', Math.round((price / props.currency.conversion) * 10) / 10)
    }

    let getLink = () => {
        return `https://www.pathofexile.com/shop/category/${props.shop_category}#microtransaction-${props.poe_id}`;
    }
    return (
        <Wrapper>
            <Icon>
                <a href={getLink()} target={'_blank'}>
                    <img src={props.image} alt={props.name}/>
                </a>
                <Colors>
                    {props.colors.map(c => <Color key={c}
                                                  onClick={() => props.onToggleColor(c)}
                                                  hex={c}/>)}
                </Colors>
                <CollectionToggle active={props.inCollection} title={'Add to collection'}
                                  onClick={() => props.onToggleCollection(props.id)}>+</CollectionToggle>
            </Icon>
            <Details>
               <Title> <a href={getLink()} target={'_blank'}>{props.name}</a></Title>
                {props.price.sale_price &&
                <Price>
                    {formatPrice(props.price.sale_price)}
                </Price>
                }
                <Price sale={props.price.sale_price !== null}>
                    {formatPrice(props.price.price)}
                </Price>
            </Details>
        </Wrapper>
    )
}

Item.propTypes = {
    inCollection: PropTypes.bool,
    onToggleCollection: PropTypes.func,
    onToggleColor: PropTypes.func
};

export default Item;