import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Sidebar from "./components/Sidebar";
import ScrollArea from 'react-scrollbar'
import styled from 'styled-components'
import ItemsGrid from "./components/ItemsGrid";

import Header from "./components/Header";
import Item from "./components/Item";
import Paginator from "./components/Paginator";
import {parse as parseQueryString} from "query-string";
import Collection from "./components/Collection";

const Wrapper = styled.div`
  display:grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows:50px 1fr auto;
  height: 100%;
  min-height: 100%;
`

// const Sidebar = styled.div`
//   grid-column: 1;
//   grid-row: 1 / 5;
//
//   background:red;
// `

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(180px,1fr));
    gap: 2em;
    // padding: ${p => `60px 1em ${60 + (p.collectionVisible ? 50 : 0)}px 1em`};
    padding:1em;
    z-index: 1000;
    overflow:scroll;
`

const queryString = parseQueryString(window.location.search);
let defaultCollectionIds = [];
let defaultIsCollectionView = false;
if (queryString.data) {
    try {
        let data = JSON.parse(atob(queryString.data));
        if (data.c) {
            defaultCollectionIds = data.c;
            defaultIsCollectionView = true;
        }
    } catch (e) {
    }
}

const App = (props) => {
    const [categories, setCategories] = useState([])
    const [items, setItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [nameFilter, setNameFilter] = useState('')
    const [limitsFilter, setLimitsFilter] = useState([-1, -1])
    const [colorFilter, setColorFilter] = useState([])

    const [pageSize, setPageSize] = useState(50)
    const [pagedItems, setPagedItems] = useState([])
    const [currency, setCurrency] = useState({conversion: 1, name: 'Points', format: '%s'})

    const [collection, setCollection] = useState(defaultCollectionIds)
    const [isCollectionView, setIsCollectionView] = useState(defaultIsCollectionView)

    const [saleFilter, setSaleFilter] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect((a) => {
        if (items.length === 0 && !loading) {
            setLoading(true);
            fetch('items.json')
                .then(r => r.json())
                .then(data => {
                    setItems(
                        data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                            .sort((a, b) => (a.date_created < b.date_created) ? 1 : ((b.date_created < a.date_created) ? -1 : 0))
                    )
                    setLoading(false);
                })
        } else {
            setFilteredItems(filterItems())
        }
        if (isCollectionView && collection.length === 0)
            setIsCollectionView(false)
    }, [items, categories, nameFilter, colorFilter, limitsFilter, isCollectionView, collection, saleFilter])

    useEffect(() => {
        if (collection.length > 0) {
            let data = btoa(JSON.stringify({c: collection}));
            let url = window.location.origin + '/?data=' + encodeURIComponent(data);
            window.history.pushState({path: url}, '', url);
        } else {
            window.history.pushState({path: '/'}, '', '/');
        }
    }, [collection])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [categories])

    let filterItems = () => {
        let types = categories.map(c => c.type)
        return items.filter(item => {
                let allow = (!saleFilter || item.price.on_sale) &&
                    (categories.length === 0 || types.indexOf(item.type) >= 0) &&
                    (nameFilter === '' || item.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0) &&
                    (colorFilter.length === 0 || intersect(colorFilter, item.colors).length > 0) &&
                    (limitsFilter[0] < 0 || item.price.price >= limitsFilter[0] || (item.price.sale_price !== null && item.price.sale_price >= limitsFilter[0])) &&
                    (limitsFilter[1] < 0 || item.price.price <= limitsFilter[1] || (item.price.sale_price !== null && item.price.sale_price <= limitsFilter[1])) &&
                    (!isCollectionView || collection.indexOf(item.id) >= 0);

                return allow;
            }
        )
    }

    let intersect = (a, b) => {
        return [...new Set(a)].filter(x => new Set(b).has(x));
    }

    let toggleColor = (color) => {
        if (Array.isArray(color)) {
            setColorFilter(color)
            return;
        }
        if (colorFilter.indexOf(color) >= 0)
            setColorFilter(colorFilter.filter(s => s !== color))
        else
            setColorFilter(([...colorFilter, color]))
    }

    let toggleCollection = (id) => {
        if (Array.isArray(id)) {
            setCollection(id)
            return;
        }
        if (collection.indexOf(id) >= 0)
            setCollection(collection.filter(s => s !== id))
        else
            setCollection([...collection, id])
    }
    return (
        <Wrapper>
            <Sidebar currency={currency}
                     onCurrencyChange={setCurrency}
                     onCategoriesChange={setCategories}
            />
            <Header selectedColors={colorFilter} onToggleColor={toggleColor}
                    sale={saleFilter}
                    onToggleSale={() => setSaleFilter(!saleFilter)}
                    onLimitsChange={setLimitsFilter}
                    onNameChange={setNameFilter}/>
            <ItemsGrid items={pagedItems} collection={collection} onToggleCollection={toggleCollection}
                       currency={currency} onToggleColor={toggleColor}/>
            <div>
                {collection.length > 0 &&
                <Collection isCollectionView={isCollectionView}
                            changeView={setIsCollectionView}
                            currency={currency}
                            onClose={() => setCollection([])}
                            items={items.filter(i => collection.indexOf(i.id) >= 0)}/>
                }
                <Paginator items={filteredItems} pageSize={pageSize} initialPage={1} onChangePage={(p) => {
                    setPagedItems(p)
                }}/>
            </div>
        </Wrapper>
    )
}

App.propTypes = {};

export default App;