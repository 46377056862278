import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Category from "./Category";
import ScrollArea from 'react-scrollbar'

const Wrapper = styled.div`
   grid-column: 1;
   grid-row: 1 / 5;
    //width: 200px;
    //height: 100%;
    //min-height: 100%;
    //position: fixed;
    background: #313438;
    z-index: 1000;
    box-shadow:1px -4px 1px rgba(0, 0, 0, 0.2), inset -1px 0px 1px rgba(100,100,100,0.3); 
    display:grid;
    grid-template-rows: auto 1fr auto;
`

const Logo = styled.a`
    display:block;
    text-transform: uppercase; 
    font-size: 1.6em;
    color:white;
    text-decoration:none;
    padding:.5em;
    font-weight: 600;
    border-bottom: 2px solid rgba(100,100,100,0.5);
`

const SmallLogo = styled.div`
    font-size: .3em;
    font-weight: 400;
    opacity: .6;
`

const Currency = styled.div`
    //position:absolute;
    //bottom:0;
    padding: 1em;
    border-top: 1px solid rgba(100,100,100,0.5);
    //right: 0;
    //left: 0;
    
    select{
        margin-left:1em;
    }
`

const Sidebar = (props) => {
    let currencies = [
        {conversion: 1, name: 'Points', format: '%s'},
        {conversion: (50 / 5), name: 'USD', format: '$%s'},
        {conversion: (50.0 / 4.4), name: 'EUR', format: '€%s'},
        {conversion: (50.0 / 13.30), name: 'BRL', format: 'R$%s'},
        {conversion: (50.0 / 233.0), name: 'RUB', format: '₽%s'},
        {conversion: (50.0 / 133.0), name: 'THB', format: '฿%s'}
    ]

    let categories = {
        'All': [],
        'Skins': [
            {name: 'Hats', type: 'hat'},
            {name: 'Boots', type: 'boots'},
            {name: 'Gloves', type: 'gloves'},
            {name: 'Armours', type: 'armour'},
            {name: 'Back Attachments', type: 'wings'},
            {name: 'Weapons', type: 'weapon'},
            {name: 'Shields', type: 'shield'},
            {name: 'Armour Set', type: 'pack'},
        ],
        'Effects': [
            {name: 'Skills', type: 'skill'},
            {name: 'Footprints', type: 'footprints'},
            {name: 'Portals', type: 'portal'},
            {name: 'Character Effects', type: 'char_effect'},
        ],
        'Pets': [{name: 'Pets', type: 'pet'}],
        'Hideout': [{name: 'Hideout', type: 'decoration'}],
        'Stash Tabs': [{name: 'Stash Tab', type: 'stash'}],
        'Bundles': [{name: 'Bundle', type: 'bundle'}]
    };

    let [currCatName, setCurrCatName] = useState('All')


    return (
        <Wrapper>
            <Logo href={'/'}>
                <SmallLogo>[POEKit]</SmallLogo>
                Nessa
            </Logo>
            <ScrollArea>
                {Object.entries(categories).map(([key, categories]) =>
                    <Category categories={categories}
                              currentCategory={currCatName}
                              title={key}
                              key={key}
                              onAll={() => {
                                  setCurrCatName(key)
                                  props.onCategoriesChange(categories)
                              }}
                              onCategory={(category) => {
                                  setCurrCatName(category.name)
                                  props.onCategoriesChange([category])
                              }}
                    >
                    </Category>
                )}
            </ScrollArea>
            <Currency>
                Currency
                <select onChange={(e) => props.onCurrencyChange(currencies.filter(c => c.name === e.target.value)[0])}
                        defaultValue={'Points'}>
                    {currencies.map(c =>
                        <option value={c.name} key={c.name}>{c.name}</option>
                    )}
                </select>
            </Currency>
        </Wrapper>
    )
}

Sidebar.propTypes = {
    currency: PropTypes.object,
    onCurrencyChange: PropTypes.func,
    onCategoriesChange: PropTypes.func
};

export default Sidebar;