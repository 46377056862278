import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
`

const MainCategory = styled.div`
    padding: 10px 20px;
    border-bottom: 1px solid rgba(100,100,100,0.5);
    cursor: pointer;
    position:relative;
    &:hover{
        background: rgba(100,100,100,0.2);
    }
    ${p => p.selected && `
        background:rgba(100,100,100,0.3);
        border-left: 4px solid #5a8bec;
        padding-left: 16px;
    `}
`

const SubCategory = styled(MainCategory)`
    background: rgba(0,0,0,0.4);
    padding: 10px 20px 10px 30px;
    ${p => p.selected && `
        padding-left: 26px;
    `}
`

const Category = (props) => {
    return (
        <Wrapper>
            <MainCategory onClick={props.onAll} selected={props.title === props.currentCategory}>{props.title}</MainCategory>
            {
                props.categories.length > 1 &&
                props.categories.map(category =>
                    <SubCategory key={category.type}
                                 selected={category.name === props.currentCategory}
                                 onClick={() => props.onCategory(category)}>{category.name}</SubCategory>
                )
            }
        </Wrapper>
    );
}

Category.propTypes = {
    currentCategory: PropTypes.string,
    onCategory: PropTypes.func,
    onAll: PropTypes.func
};

export default Category;